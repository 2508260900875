export class Message {
    constructor(
        public sender: string,
        public text: string,
        public sticker?: string,
        public sticker_emoji?: string
    ) {}
}

export class DisplayMessage {
    constructor(
        public m: Message,
        public oai_index: number,
        public line_number: number
    ) { }
}

export function JSONLToMessages(input: string, index: number): DisplayMessage[] {
    const messages: DisplayMessage[] = [];
    const lines = input.split('\n');

    for (const [i, line] of lines.entries()) {
        if (line.trim() === '') continue; // Skip empty lines
        try {
            const message = Object.assign(new Message("", ""), JSON.parse(line));
            messages.push(new DisplayMessage(message, index, i));
        } catch (error) {
            // If parsing fails, skip the line and continue with the next
            console.warn('Error parsing line:', line, '; Error:', error);
            continue;
        }
    }

    return messages;
}

export default Message;
