import Message from "./Message";

export class OpenAIMessage {
    constructor(
        public role: string = "user",
        public content: string = "",
    ) { }

    static fromMessage(message: Message): OpenAIMessage {
        const openAIMessage = new OpenAIMessage();
        openAIMessage.content = JSON.stringify(message);
        return openAIMessage;
    }
}

export default OpenAIMessage;