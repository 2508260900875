import React, { useState } from 'react';

// Define a type for the settings data
export type SettingsData = {
    secretKey: string;
    temperature: number;
    max_tokens: number;
    model: string; // new field for the model
};

// Update SettingsProps to use SettingsData
interface SettingsProps {
    initialSettings: SettingsData;
    onSave: (settings: SettingsData) => void;
}

// Constant mapping human-readable model names to database model names
const MODEL_OPTIONS = {
    "Mid": "ft:gpt-3.5-turbo-1106:the-fam::8KH0ZTxd",
    "Dumb": "ft:gpt-3.5-turbo-1106:the-fam::8KExBgWA",
    "Retarded": "ft:gpt-3.5-turbo-1106:the-fam::8KGnHD2O"
};

const Settings: React.FC<SettingsProps> = ({ initialSettings, onSave }) => {
    const [secretKey, setSecretKey] = useState(initialSettings.secretKey);
    const [temperature, setTemperature] = useState(initialSettings.temperature);
    const [maxTokens, setMaxTokens] = useState(initialSettings.max_tokens);
    const [model, setModel] = useState(initialSettings.model); // state for the model

    const handleSave = () => {
        // Create an object with the current state values
        const updatedSettings: SettingsData = {
            secretKey: secretKey,
            temperature: temperature,
            max_tokens: maxTokens,
            model: model // include the model in the settings
        };

        // Call the onSave callback with the updated settings
        onSave(updatedSettings);

        // Save the settings to local storage
        localStorage.setItem('secretKey', secretKey);
        localStorage.setItem('temperature', temperature.toString());
        localStorage.setItem('max_tokens', maxTokens.toString());
        localStorage.setItem('model', model); // save model to local storage
    };

    return (
        <div className="settings">
            <label htmlFor="secretKey">Secret Key:</label>
            <input
                id="secretKey"
                type="text"
                placeholder="Secret key"
                value={secretKey}
                onChange={(e) => setSecretKey(e.target.value)}
            />

            <label htmlFor="temperature">Temperature (0-2):</label>
            <input
                id="temperature"
                type="number"
                placeholder="Temperature (0-1.2)"
                min="0"
                max="1.2"
                step="0.1"
                value={temperature}
                onChange={(e) => setTemperature(parseFloat(e.target.value))}
            />

            <label htmlFor="maxTokens">Max Tokens (0-2048):</label>
            <input
                id="maxTokens"
                type="number"
                placeholder="Max Tokens (0-2048)"
                min="0"
                max="2048"
                value={maxTokens}
                onChange={(e) => setMaxTokens(parseInt(e.target.value))}
            />

            <label htmlFor="model">Model:</label>
            <select
                id="model"
                value={model}
                onChange={(e) => setModel(e.target.value)}
            >
                {Object.entries(MODEL_OPTIONS).map(([name, value]) => (
                    <option key={value} value={value}>{name}</option>
                ))}
            </select>

            <button onClick={handleSave}>Save</button>
        </div>
    );
};

export default Settings;
