export class Fam {
    name: string;
    pic: string;

    constructor(name: string, pic: string) {
        this.name = name;
        this.pic = pic;
    }
}

export const fams = [
    new Fam('Simon McFarlane', ""),
    new Fam('Ben Wood', ""),
    new Fam('Jevon Streicher', ""),
    new Fam('David Thomas', ""),
    new Fam('Zach Meskell', ""),
]
