import React from 'react';
import { JSONLToMessages, DisplayMessage } from './Message';
import { RenderSticker } from './Stickers';
import OpenAIMessage from './OpenAIMessage';

interface MessageListProps {
    messages: OpenAIMessage[];
    doDelete: (index: number, line: number) => void;
}

interface MessageListState {

}


class MessageList extends React.Component<MessageListProps, MessageListState> {
    render() {
        const display_messages: [DisplayMessage, string][] = [];
        for (const [i, openAIMessage] of this.props.messages.entries()) {
            console.debug("Parsing message:", openAIMessage);
            if (openAIMessage.role === "user" || openAIMessage.role === "assistant") {
                const messagesFromContent = JSONLToMessages(openAIMessage.content, i);
                display_messages.push(...(messagesFromContent.map((x) => [x, openAIMessage.role]) as [DisplayMessage, string][]));
            }
        }
        return (
            <div>
                {display_messages.map((x, idx) => {
                    return (<li className={`message role-${x[1]}`} key={idx}>
                        <div className="message-author">{x[0].m.sender}</div>
                        <div className="message-body">{RenderSticker(x[0].m)}</div>
                        <button className="message-delete" onClick={() => this.props.doDelete(x[0].oai_index, x[0].line_number)}>X</button>
                    </li>)
                })}
            </div>
        )
    }
}

export default MessageList;
