import React from 'react';
import './App.css';
import Message from './Message';
import OpenAIMessage from './OpenAIMessage';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import Settings, { SettingsData } from './Settings';

interface AppProps { }

interface AppState {
    messages: OpenAIMessage[],
    showSettings: boolean,
    secret_key: string,
    temperature: number,
    max_tokens: number,
    model: string,
}

const API_URL = 'https://api.bongsmoke.org/complete'
const PROMPT = new OpenAIMessage("system", `You are parodying a group chat of five dudes named Ben Wood, David Thomas, Jevon Streicher, Simon McFarlane, and Zach Meskell. These five irreverent guys like to crack obscene jokes and talk about life. They are all smart and eloquent, but they're also sardonic, raunchy and humorous. They like to use chat stickers in ironic ways.

You get messages in JSON format, and you should return a series of responses in JSONL format (each message delineated by a newline). Use stickers by adding the \`sticker\` and \`sticker_emoji\` fields to a message. Stickers 000.webp through 381.webp are available.`);

function removeLine(messages: OpenAIMessage[], index: number, line: number) {
    return messages.map((message, i) => {
        if (index === i) {
            let newMessage = structuredClone(message);
            let result = newMessage.content.split('\n');
            result.splice(line, 1);
            newMessage.content = result.join('\n');
            return newMessage;
        }
        return message;
    });
}


class App extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        this.state = {
            messages: [PROMPT],
            secret_key: localStorage.getItem('secretKey') || "",
            showSettings: false,
            temperature: parseFloat(localStorage.getItem('temperature') || "0.85"),
            max_tokens: parseInt(localStorage.getItem('max_tokens') || "256"),
            model: localStorage.getItem('model') || "ft:gpt-3.5-turbo-1106:the-fam::8KH0ZTxd"
        }
    }

    readonly addMessages = (data: OpenAIMessage) => {
        this.setState(prevState => ({
            messages: [...prevState.messages, data]
        }));
    }

    readonly complete = () => {
        fetch(API_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                messages: this.state.messages, secret_key: this.state.secret_key, temperature: this.state.temperature,
                max_tokens: this.state.max_tokens, model: this.state.model,
            }),
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === 'ok') {
                    const result: OpenAIMessage = Object.assign(new OpenAIMessage(), data.completion.choices[0].message);
                    console.debug(result.content);
                    const lines = result.content.split('\n').filter((x) => { try { JSON.parse(x) } catch (e) { return false; }; return true; })
                    this.addMessages(new OpenAIMessage(result.role, lines.join('\n')));
                }
                else {
                    alert(data.error);
                }
            });
    }

    readonly handleSend = (message: Message) => {
        if (!this.state.secret_key) {
            alert('Need to input a secret key')
            return;
        }
        if (!message.sender) {
            alert('You need to select an author');
            return;
        }
        var body = [...this.state.messages, OpenAIMessage.fromMessage(message)];
        this.setState(prevState => ({
            messages: body,
        }), this.complete)
    }

    readonly handleDelete = (index: number, line: number) => {
        console.debug("Deleting message at index", index, "line", line);
        this.setState(prevState => ({
            messages: removeLine(prevState.messages, index, line)
        }))
    }

    readonly regenerate = () => {
        var body = this.state.messages.slice(0, -1);
        this.setState({
            messages: body,
        }, this.complete)
    }

    readonly clear = () => {
        this.setState({
            messages: [PROMPT],
        })
    }

    readonly regenClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.regenerate();
    }

    readonly moreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.complete();
    }

    readonly clearClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.clear();
    }

    toggleSettings = () => {
        this.setState(prevState => ({ showSettings: !prevState.showSettings }));
    }

    saveSettings = (settings: SettingsData) => {
        this.setState({
            secret_key: settings.secretKey,
            temperature: settings.temperature,
            max_tokens: settings.max_tokens,
            model: settings.model,
        });
    }

    render() {
        return (
            <div>
                <div id="display">
                    <MessageList messages={this.state.messages} doDelete={this.handleDelete} />
                </div>
                <MessageInput onSend={this.handleSend} />
                <button onClick={this.regenClick}>Regenerate</button>
                <button onClick={this.moreClick}>More</button>
                <button onClick={this.clearClick}>Clear</button>
                <br />
                <button onClick={this.toggleSettings}>Settings</button>
                {this.state.showSettings && (
                    <Settings
                        initialSettings={{
                            secretKey: this.state.secret_key,
                            temperature: this.state.temperature,
                            max_tokens: this.state.max_tokens,
                            model: this.state.model,
                        }}
                        onSave={this.saveSettings}
                    />
                )}
            </div>
        )
    }
}

export default App;
