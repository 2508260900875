import Message from "./Message";

export function RenderSticker(m: Message) {
    if (m.sticker) {
        return (
            <img className="sticker" alt={m.sticker_emoji} title={m.sticker_emoji} src={`stickers/${m.sticker}`} />
        )
    } else {
        return m.text;
    }
}
