import React from 'react';
import Message from './Message';
import { fams } from './Fam';

interface MessageInputProps {
    onSend: (message: Message) => void;
}

interface MessageInputState {
    author: string;
    content: string;
}

class MessageInput extends React.Component<MessageInputProps, MessageInputState> {
    constructor(props: MessageInputProps) {
        super(props);
        this.state = {
            author: "",
            content: "",
        }
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ content: event.target.value });
    }

    handleButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        this.send();
    }

    readonly handleSubmit = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            this.send();
        }
    }

    readonly handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({
            author: event.target.value,
        });
    }

    send() {
        this.setState({content: ''});
        this.props.onSend(new Message(this.state.author, this.state.content));
    }

    render() {
        return (
            <div>
                <select onChange={this.handleSelect} defaultValue="xxx">
                    <option disabled value="xxx">Choose your figher</option>
                    {fams.map(function (x, idx) {
                        return (
                            <option key={x.name} value={x.name}>{x.name}</option>
                        )
                    })}
                </select>
                <input
                    type="text"
                    value={this.state.content}
                    onKeyDown={this.handleSubmit}
                    onChange={this.handleChange.bind(this)}
                    className="send-input"
                />
                <button onClick={this.handleButtonClick.bind(this)}>Send</button>
            </div>
        )
    }
}

export default MessageInput;
